// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import notification from '@modules/antd/lib/notification';
import { PluginEntryPoint, ComponentBuilder } from '@root/components/plugins-entrypoint';
import { BaseSingleFrameAction, ShapeType } from '@root/cvat-core-wrapper';
import { ConvertMethod, WorkerAction } from './actions-worker';

const pluginName = 'Enhanced set of annotations actions';

const builder: ComponentBuilder = ({ core }) => {
    let worker: Worker | null = null;

    const converterFactory = (method: ConvertMethod): typeof BaseSingleFrameAction => {
        let shapeFrom: ShapeType = ShapeType.RECTANGLE;
        let shapeTo: ShapeType = ShapeType.RECTANGLE;

        if (method === ConvertMethod.MASK_TO_POLYGON) {
            shapeFrom = ShapeType.MASK;
            shapeTo = ShapeType.POLYGON;
        } else if (method === ConvertMethod.MASK_TO_RECT) {
            shapeFrom = ShapeType.MASK;
            shapeTo = ShapeType.RECTANGLE;
        } else if (method === ConvertMethod.POLYGON_TO_MASK) {
            shapeFrom = ShapeType.POLYGON;
            shapeTo = ShapeType.MASK;
        } else if (method === ConvertMethod.POLYGON_TO_RECT) {
            shapeFrom = ShapeType.POLYGON;
            shapeTo = ShapeType.RECTANGLE;
        } else if (method === ConvertMethod.RECT_TO_MASK) {
            shapeFrom = ShapeType.RECTANGLE;
            shapeTo = ShapeType.MASK;
        } else if (method === ConvertMethod.RECT_TO_POLYGON) {
            shapeFrom = ShapeType.RECTANGLE;
            shapeTo = ShapeType.POLYGON;
        }

        return class extends BaseSingleFrameAction {
            private shownWarnings: boolean = false;

            public async init(): ReturnType<BaseSingleFrameAction['init']> {
                return new Promise((resolve, reject) => {
                    this.shownWarnings = false;
                    worker = new Worker(new URL('./actions-worker.ts', import.meta.url));
                    worker.onmessage = (event: MessageEvent) => {
                        const result = event.data;
                        if (result.error) {
                            reject(new Error(result.error));
                        } else {
                            resolve();
                        }
                    };

                    worker.onerror = (event: ErrorEvent) => {
                        reject(new Error(event.message));
                    };

                    worker.postMessage({ command: WorkerAction.INITIALIZE });
                });
            }

            public async destroy(): ReturnType<BaseSingleFrameAction['destroy']> {
                worker.terminate();
                worker = null;
            }

            public async run(
                _: Parameters<BaseSingleFrameAction['run']>[0],
                input: Parameters<BaseSingleFrameAction['run']>[1],
            ): ReturnType<BaseSingleFrameAction['run']> {
                return new Promise((resolve, reject) => {
                    const relevantShapes = input.collection.shapes.filter((shape) => shape.type === shapeFrom);
                    if (relevantShapes.length) {
                        const otherObjects = input.collection.shapes
                            .filter((shape) => !relevantShapes.includes(shape));

                        worker.onmessage = (event: MessageEvent) => {
                            const result = event.data;
                            if (result.error) {
                                reject(new Error(result.error));
                            } else {
                                if (result.warnings.length) {
                                    console.warn(result.warnings.join('\n\n'));
                                    if (!this.shownWarnings) {
                                        this.shownWarnings = true;
                                        notification.warning({
                                            duration: 0,
                                            message: 'Warnings appeared during conversion',
                                            description: 'Please, open the browser console for details',
                                        });
                                    }
                                }
                                input.collection.shapes.push(...result.shapes);
                                resolve({ collection: { shapes: [...otherObjects, ...result.shapes] } });
                            }
                        };

                        worker.onerror = (event: ErrorEvent) => {
                            reject(new Error(event.message));
                        };

                        worker.postMessage({
                            command: WorkerAction.RUN,
                            method,
                            shapes: relevantShapes,
                            height: input.frameData.height,
                            width: input.frameData.width,
                        });
                    } else {
                        resolve({ collection: input.collection });
                    }
                });
            }

            public get name(): BaseSingleFrameAction['name'] {
                return `Shapes converter: ${shapeFrom}s to ${shapeTo}s`;
            }

            public get parameters(): BaseSingleFrameAction['parameters'] {
                return null;
            }
        };
    };

    core.actions.register(new (converterFactory(ConvertMethod.MASK_TO_POLYGON))());
    core.actions.register(new (converterFactory(ConvertMethod.MASK_TO_RECT))());
    core.actions.register(new (converterFactory(ConvertMethod.POLYGON_TO_MASK))());
    core.actions.register(new (converterFactory(ConvertMethod.POLYGON_TO_RECT))());
    core.actions.register(new (converterFactory(ConvertMethod.RECT_TO_MASK))());
    core.actions.register(new (converterFactory(ConvertMethod.RECT_TO_POLYGON))());

    return {
        name: pluginName,
        destructor: () => {},
    };
};

function register(): void {
    if (Object.prototype.hasOwnProperty.call(window, 'cvatUI')) {
        (window as any as { cvatUI: { registerComponent: PluginEntryPoint } })
            .cvatUI.registerComponent(builder);
    }
}

window.addEventListener('plugins.ready', register, { once: true });
